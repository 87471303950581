import View from './View'

const user = {
  path: '/userData',
  component: View,
  children: [
    {
      path: '',
      name: 'user-data',
      component: () =>
        import( /* webpackChunkName:'UserData' */ '@/views/UserData'),
      meta: {
        title: "用户资料",
        isShow: true
      }
    },
    {
      path: 'name',
      name: 'name',
      component: () =>
        import( /* webpackChunkName:'Name' */ '@/views/UserData/Name'),
      meta: {
        title: "真实姓名",
        isShow: true
      }
    },
    {
      path: 'changeTel',
      name: 'change-tel',
      component: () =>
        import( /* webpackChunkName:'ChangeTel' */ '@/views/UserData/ChangeTel'),
      meta: {
        title: "更换手机号",
        isShow: true
      }
    },
    {
      path: 'password',
      name: 'password',
      component: () =>
        import( /* webpackChunkName:'Password' */ '@/views/UserData/Password'),
      meta: {
        title: "登录密码",
        isShow: true
      }
    },
    {
      path: 'address',
      name: 'address',
      component: () =>
        import( /* webpackChunkName:'Address' */ '@/views/UserData/Address'),
      meta: {
        title: "选择地址",
        isShow: true
      }
    },
    {
      path: 'addAddress',
      name: 'add-Address',
      component: () =>
        import( /* webpackChunkName:'AddAddress' */ '@/views/UserData/AddAddress'),
      meta: {
        title: "用户地址",
        isShow: true
      },
    },
    {
      path: 'integral',
      name: 'integral',
      component: () =>
        import( /* webpackChunkName:'Integral' */ '@/views/UserData/Integral'),
    },
    {
      path: 'wallet',
      component: View,
      children: [
        {
          path: '',
          name: 'wallet',
          component: () =>
            import( /* webpackChunkName:'CapitalAccount' */ '@/views/UserData/Wallet'),
          meta: {
            title: "我的钱包",
            isShow: true
          },
        },
        {
          path: 'capitalAccount',
          name: 'capital-account',
          component: () =>
            import( /* webpackChunkName:'CapitalAccount' */ '@/views/UserData/Wallet/CapitalAccount'),
          meta: {
            title: "资金账户",
            isShow: true
          },
        }
      ]
    },
    {
      path: 'bankCard',
      name: 'bank-card',
      component: () =>
        import( /* webpackChunkName:'BankCard' */ '@/views/UserData/BankCard'),
      meta: {
        title: "我的银行卡",
        isShow: true
      },
    },
    {
      path: 'signIn',
      name: 'sign-in',
      component: () =>
        import( /* webpackChunkName:'SignIn' */ '@/views/UserData/SignIn'),
      meta: {
        title: "我的签到",
        isShow: true,
        color: '#fff',
        background: '#FF9B2D'
      },
    }
  ]
}

export default user