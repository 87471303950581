import request from '@/utils/userRequest' //20050

//获取当前用户信息
export async function getUser(data) {
	return await request({
		url: '/consumer-user-server/User/getCurrUser',
		method: 'post',
		data
	})
}
//根据id获取用户信息
export async function findUserById(data) {
	return await request({
		url: '/consumer-user-server/User/findUserById',
		method: 'post',
		data
	})
}

//根据用户id查找默认团长自提点id
export async function findpurchaseIdByuserId(data) {
	return await request({
		url: '/consumer-user-server/Address/findpurchaseIdByuserId',
		method: 'post',
		data
	})
}

//修改用户信息
export async function updateUser(data) {
	return await request({
		url: '/consumer-user-server/User/updateUser',
		method: 'post',
		data
	})
}
//修改手机号用户名
export async function updateUserName(data) {
	return await request({
		url: '/consumer-user-server/User/updateUserName',
		method: 'post',
		data
	})
}
//验证原密码
export async function matchePassword(data) {
	return await request({
		url: '/consumer-user-server/User/matchePassword',
		method: 'post',
		data
	})
}

//新增收货地址
export async function insertAddress(data) {
	return await request({
		url: '/consumer-user-server/Address/insertAddress',
		method: 'post',
		data
	})
}
//查询收货地址
export async function queryAddress(data) {
	return await request({
		url: '/consumer-user-server/Address/queryAddress',
		method: 'post',
		data
	})
}
//修改地址
export async function updateAddress(data) {
	return await request({
		url: '/consumer-user-server/Address/updateAddress',
		method: 'post',
		data
	})
}

//删除地址
export async function deleteAddress(data) {
	return await request({
		url: '/consumer-user-server/Address/deleteAddress',
		method: 'post',
		data
	})
}
//手机号修改密码
export async function updatePasswdByUserName(data) {
	return await request({
		url: '/consumer-user-server/User/updatePasswdByUserName',
		method: 'post',
		data
	})
}
//修改为团长
export async function updateUserType(data) {
	return await request({
		url: '/consumer-user-server/User/updateUserType',
		method: 'post',
		data
	})
}
//个人开户
export async function cusApplications(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/cusApplications',
		method: 'post',
		data
	})
}
//银行短信
export async function sendSmsCodesByMobile(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/sendSmsCodesByMobile',
		method: 'post',
		data
	})
}
//查询开户状态
export async function queryApplications(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/queryApplications',
		method: 'post',
		data
	})
}
//查询资金账户信息
export async function queryElectronicAcct(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/queryElectronicAcct',
		method: 'post',
		data
	})
}
//查询银行卡列表
export async function selectSettleAcctList(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/selectSettleAcctList',
		method: 'post',
		data
	})
}
//查询个人进件信息
export async function queryCusApplicationsInfo(data) {
	return await request({
		url: '/consumer-user-server/consumerUserFunds/queryCusApplicationsInfo',
		method: 'post',
		data
	})
}
//同意协议推广
export async function addPromoter(data) {
	return await request({
		url: '/consumer-user-server/User/addPromoter',
		method: 'post',
		data
	})
}
//查询是否分佣推广员
export async function queryPromoterById(data) {
	return await request({
		url: '/consumer-user-server/User/queryPromoterById',
		method: 'post',
		data
	})
}
//推广员信息
export async function promoterInfo(data) {
	return await request({
		url: '/consumer-shopping-server/Promoter/PromoterInfo',
		method: 'post',
		data
	})
}
//分佣规则
export async function commissionRuleDetails(data) {
	return await request({
		url: '/consumer-shopping-server/Promoter/CommissionRuleDetails',
		method: 'post',
		data
	})
}
//推广员订单列表
export async function promoterOrderList(data) {
	return await request({
		url: '/consumer-shopping-server/Promoter/PromoterOrderList',
		method: 'post',
		data
	})
}

// ----------------------积分商城----------------------

//调整积分
export async function AdjustmentIntegral(data) {
	return await request({
		url: '/consumer-user-server/Integral/AdjustmentIntegral',
		method: 'post',
		data,
	})
}
//新增积分记录
export async function InsertIntegralRecord(data) {
	return await request({

		url: '/consumer-user-server/Integral/InsertIntegralRecord',
		method: 'post',
		data
	})
}
//会员积分明细
export async function IntegralDetailedList(data) {
	return await request({
		url: '/consumer-user-server/Integral/IntegralDetailedList',
		method: 'post',
		data
	})
}
//银行会员标识
export async function CusBankFilePathList(data) {
	return await request({
		url: '/consumer-user-server/Integral/CusBankFilePathList',
		method: 'post',
		data
	})
}
//消费增积分
export async function BonusIntegral(data) {
	return await request({
		url: '/consumer-user-server/Integral/BonusIntegral',
		method: 'post',
		data
	})
}

//注册赠送积分
export async function RegisteredBonusIntegral(data) {
	return await request({
		url: '/consumer-user-server/Integral/RegisteredBonusIntegral',
		method: 'post',
		data
	})
}
//查询注册是否送积分
export async function RegisteredBonusState(data) {
	return await request({
		url: '/consumer-user-server/Integral/RegisteredBonusState',
		method: 'post',
		data
	})
}

//积分商品礼列表
export async function IntegralCommodityList(data) {
	return await request({
		url: '/consumer-integral-server/IntegralCommodity/IntegralCommodityList',
		method: 'post',
		data
	})
}

//积分商品详情
export async function IntegralCommodityDetails(data) {
	return await request({
		url: '/consumer-integral-server/IntegralCommodity/IntegralCommodityDetails',
		method: 'post',
		data
	})
}

//积分商城分类
export async function queryCategoriesCatalog(data) {
	return await request({
		url: '/consumer-integral-server/Classify/queryCategoriesCatalog',
		method: 'post',
		data
	})
}

// ----------------------签到----------------------

//新增签到记录
export async function insertCheckInRecord(data) {
	return await request({
		url: '/consumer-shopping-server/CheckInRecord/InsertCheckInRecord',
		method: 'post',
		data
	})
}

//签到规则详情
export async function checkInRuleDetails(data) {
	return await request({
		url: '/consumer-shopping-server/CheckInRule/CheckInRuleDetails',
		method: 'post',
		data
	})
}

//签到记录
export async function checkInRecord(data) {
	return await request({
		url: '/consumer-shopping-server/CheckInRecord/CheckInRecord',
		method: 'post',
		data
	})
}

// ----------------------任务----------------------

//任务列表
export async function taskQueryList(data) {
	return await request({
		url: '/consumer-integral-server/task/taskFinishedQuery',
		method: 'post',
		data
	})
}

//完成任务
export async function taskFinished(data) {
	return await request({
		url: '/consumer-integral-server/task/taskFinished',
		method: 'post',
		data
	})
}

//获取跳转小程序 URL Schema
export async function generatescheme(data) {
	return await request({
		url: '/generatescheme',
		method: 'post',
		data
	})
}