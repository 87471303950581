var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"van-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"function_button",style:([{ bottom: _vm.btnInfo.length > 3 ? '2rem' : '1.6rem' }])},_vm._l((_vm.btnInfo),function(item,i){return _c('div',{key:i,class:_vm.showBtn ? 'open' : '',style:([
        {
          transform: _vm.showBtn
            ? ("translate(" + (_vm.translation[i].x) + "," + (_vm.translation[i].y) + ")")
            : '',
        } ]),on:{"click":item.function}},[_c('svg-icon',{style:([
          {
            transform:
              _vm.showBtn && item.icon === 'feature' ? 'rotate(-45deg)' : '',
          } ]),attrs:{"icon-class":item.icon}}),(item.text)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }