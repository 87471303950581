import login from './login'
import register from './register'
import homePage from './homePage'
import activity from './activity'
import news from './news'
import store from './store'
import user from './user'
import myOrder from './myOrder'
import payOrder from './payOrder'
import pointsMall from './pointsMall'
import promotion from './promotion'

const routes = [


  login,
  register,
  homePage,
  activity,
  news,
  store,
  user,
  myOrder,
  payOrder,
  {
    path: '/goodsList',
    name: 'goods-list',
    component: () =>
      import( /* webpackChunkName:'GoodsList' */ '@/views/GoodsList'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/goodsDetails',
    name: 'goods-details',
    component: () =>
      import(/* webpackChunkName:'GoodsDetails' */  '@/views/Goods'),
  },
  {
    path: '/selectedShops',
    name: 'selected-shops',
    component: () =>
      import( /* webpackChunkName:'SelectedShops' */ '@/views/Store/SelectedShops'),
    meta: {
      title: "全部店铺",
      isShow: true,
      keepAlive: true
    },
  },
  {
    path: '/brand',
    name: 'brand',
    component: () =>
      import( /* webpackChunkName:'Brand' */ '@/views/Brand'),
    meta: {
      title: "全部品牌",
      isShow: true
    },
  },
  {
    path: '/brandDetail',
    name: 'brandDetail',
    component: () =>
      import( /* webpackChunkName:'BrandDetail' */ '@/views/Brand/BrandDetail'),
    meta: {
      title: "推荐品牌",
      isShow: true,
    },
  },
  {
    path: '/set',
    name: 'set',
    component: () =>
      import( /* webpackChunkName:'Set' */ '@/views/UserData/Set'),
    meta: {
      title: "设置",
      isShow: true
    }
  },
  {
    path: '/policyOfPoints',
    name: 'policy-of-points',
    component: () =>
      import( /* webpackChunkName:'PolicyOfPoints' */ '@/views/PolicyOfPoints'),
    meta: {
      title: "积分政策",
    }
  },
  {
    //晒单海报
    path: '/singlePoster',
    name: 'single-poster',
    component: () =>
      import( /* webpackChunkName:'SinglePoster' */ '@/views/SinglePoster'),
  },
  {
    path: '/earlyAdopters',
    name: 'early-adopters',
    meta: {
      title: "新人尝鲜价",
      isShow: true,
      noFixed: true,
      transparent: true,
    },
    component: () =>
      import(/* webpackChunkName:'EarlyAdopters' */  '@/views/EarlyAdopters'),
  },
  {
    path: '/seckill',
    name: 'seckill',
    meta: {
      title: "限时秒杀",
      isShow: true,
      color: '#fff',
      background: "linear-gradient(180deg, #f91c12 0%, #fa261d 100%)"
    },
    component: () =>
      import(/* webpackChunkName:'Seckill' */  '@/views/Seckill'),
  },
  {
    path: '/hotList',
    name: 'hot-list',
    meta: {
      title: "热销榜单",
      isShow: true,
      color: '#fff',
      background: "linear-gradient(180deg, #ea0411 0%, #ed120e 100%)"
    },
    component: () =>
      import(/* webpackChunkName:'HotList' */  '@/views/HotList'),
  },
  {
    path: '/specialArea/newProductExpress',
    name: 'new-product-express',
    component: () =>
      import( /* webpackChunkName:'NewProductExpress' */ '@/views/SpecialArea'),
    meta: {
      title: "新品速递",
      isShow: true,
      noFixed: true,
      transparent: true,
      keepAlive: true
    }
  },
  {
    path: '/specialArea/greatValueSpecial',
    name: 'great-value-special',
    component: () =>
      import( /* webpackChunkName:'GreatValueSpecial' */ '@/views/SpecialArea'),
    meta: {
      title: "超值特惠",
      isShow: true,
      noFixed: true,
      transparent: true,
      keepAlive: true
    }
  },
  {
    path: '/community',
    name: 'community',
    component: () =>
      import( /* webpackChunkName:'Community' */ '@/views/Community'),
    meta: {
      title: "加入正品汇社群",
      isShow: true,
      noFixed: true,
      transparent: true,
      keepAlive: true
    }
  },
  pointsMall,
  promotion,




































  {
    path: '/D',
    name: 'distributed-ledger',
    meta: {
      title: "分布式账册",
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'DistributedLedger' */ '@/views/DistributedLedger'),
  }, {
    path: '/Bill',
    name: 'bill',
    meta: {
      title: "账单详情",
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'Bill' */ '@/views/Bill'),
  }, {
    path: '/InsurancePolicy',
    name: 'insurance-policy',
    meta: {
      title: "电子保单",
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'InsurancePolicy' */ '@/views/InsurancePolicy'),
  }, {
    path: '/LogisticsTrajectory',
    name: 'logistics-trajectory',
    meta: {
      title: "物流轨迹",
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'LogisticsTrajectory' */ '@/views/LogisticsTrajectory'),
  }, {
    path: '/InspectionInformation',
    name: 'inspection-information',
    meta: {
      title: '检测信息',
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'InspectionInformation' */ '@/views/InspectionInformation'),
  }, {
    path: '/GeographicalIndications',
    name: 'geographical-indications',
    meta: {
      title: '原产地（地理标志）',
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'GeographicalIndications' */ '@/views/GeographicalIndications'),
  }, {
    path: '/AntiFake',
    name: 'anti-fake',
    meta: {
      title: '赋码防伪',
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'AntiFake' */ '@/views/AntiFake'),
  }, {
    path: '/test',
    name: 'test',
    meta: {
      skip: true
    },
    component: () =>
      import( /* webpackChunkName: "Test" */ '@/views/test'),
  }, {
    path: '/test2',
    name: 'test2',
    meta: {
      skip: true
    },
    component: () =>
      import( /* webpackChunkName: "Test" */ '@/views/maptest'),
  }, {
    path: '*',
    name: 'NotFound',
    meta: {
      title: "页面未找到",
      skip: true
    },
    component: () =>
      import( /* webpackChunkName:'NotFound’' */ '@/views/NotFound'),
  },
]

export default routes